import { get } from "shared/lib/api"
import { rejectedXHRPromiseLogger } from "shared/lib/xhr_promise"

export const initialState = {
  applets: [],
  appletOffset: 32,
  services: [],
  recommendedServices: [],
  searchTerm: "",
  appletsLoading: false,
  searchLoading: false,
  hideSuggestions: true,
  resultsAreStale: false,
  shouldLoadMoreApplets: true,
  signedIn: false,
}

const setApplets = applets => {
  return {
    type: "SET_SEARCH_APPLETS",
    applets,
  }
}

const setServices = services => {
  return {
    type: "SET_SEARCH_SERVICES",
    services,
  }
}

const fetchMoreApplets = applets => {
  return {
    type: "FETCH_MORE_APPLETS",
    applets,
  }
}

const checkResultsAreFresh = applets => {
  return {
    type: "CHECK_RESULTS_ARE_FRESH",
    applets,
  }
}

const incrementAppletOffset = {
  type: "INCREMENT_APPLET_OFFSET",
}

const setAppletsLoading = isLoading => {
  return {
    type: "TOGGLE_APPLETS_LOADING",
    isLoading,
  }
}

const setSearchLoading = isLoading => {
  return {
    type: "TOGGLE_SEARCH_LOADING",
    isLoading,
  }
}

const setShouldLoadMoreApplets = shouldLoadMoreApplets => {
  return {
    type: "SET_SHOULD_LOAD_APPLETS",
    shouldLoadMoreApplets,
  }
}

const toggleShouldHideSuggestions = shouldHideSuggestions => {
  return {
    type: "HIDE_SUGGESTIONS",
    shouldHideSuggestions,
  }
}

export const setSearchTerm = term => {
  return {
    type: "SET_SEARCH_TERM",
    term,
  }
}

export const fetchSearchResults = term => dispatch => {
  dispatch(setSearchLoading(true))
  const searchPath = `/search/query/${encodeURIComponent(term)}.json`

  const fetchWithRetry = (attempt = 1) => {
    get(searchPath)
      .then(results => {
        if (results[0].length === 0 && results[1].length === 0) {
          dispatch(toggleShouldHideSuggestions(false))
        } else {
          dispatch(setApplets(results[0]))
          dispatch(setServices(results[1]))
          dispatch(setShouldLoadMoreApplets(true))
          dispatch(incrementAppletOffset)
          dispatch(toggleShouldHideSuggestions(true))
        }
        dispatch(setSearchLoading(false))
      })
      .catch(() => {
        if (attempt < 2) {
          fetchWithRetry(attempt + 1)
        } else {
          rejectedXHRPromiseLogger()
          dispatch(setSearchLoading(false))
        }
      })
  }

  fetchWithRetry()
}

export const loadMoreApplets = (offset, term) => dispatch => {
  dispatch(setAppletsLoading(true))
  const morePath = `/search/more_applets/${encodeURIComponent(term)}/${offset}.json`

  get(morePath)
    .then(results => {
      if (results.length) {
        dispatch(fetchMoreApplets(results))
        dispatch(checkResultsAreFresh(results))
        dispatch(incrementAppletOffset)
      } else {
        dispatch(setShouldLoadMoreApplets(false))
      }
      dispatch(setAppletsLoading(false))
    })
    .catch(
      rejectedXHRPromiseLogger(() => {
        dispatch(setAppletsLoading(false))
      })
    )
}

export default function searchResults(state = initialState, action) {
  switch (action.type) {
    case "SET_SEARCH_APPLETS":
      return {
        ...state,
        applets: action.applets,
      }

    case "SET_SEARCH_SERVICES":
      return {
        ...state,
        services: action.services,
      }

    case "FETCH_MORE_APPLETS":
      return {
        ...state,
        applets: [...state.applets, ...action.applets].filter((applet, index, self) => self.indexOf(applet) === index),
      }

    case "CHECK_RESULTS_ARE_FRESH":
      return {
        ...state,
        resultsAreStale: !action.applets.find(appletResult => !state.applets.map(a => a.id).includes(appletResult.id)),
      }

    case "INCREMENT_APPLET_OFFSET":
      // magic number 32 matches `RESULTS_LIMIT` in get_more_controller.rb
      return {
        ...state,
        appletOffset: state.appletOffset + 32,
      }

    case "FETCH_MORE_SERVICES":
      return {
        ...state,
        services: [...state.services, ...action.services],
      }

    case "SET_SEARCH_TERM":
      return {
        ...state,
        searchTerm: action.term,
        resultsAreStale: false,
        appletOffset: 0,
      }

    case "TOGGLE_APPLETS_LOADING":
      return {
        ...state,
        appletsLoading: action.isLoading,
      }

    case "TOGGLE_SEARCH_LOADING":
      return {
        ...state,
        searchLoading: action.isLoading,
      }

    case "SET_SHOULD_LOAD_APPLETS":
      return {
        ...state,
        shouldLoadMoreApplets: action.shouldLoadMoreApplets,
      }

    case "TOGGLE_SEARCH_VIEW":
      return {
        ...state,
        currentSearchView: action.view,
      }

    case "HIDE_SUGGESTIONS":
      return {
        ...state,
        hideSuggestions: action.shouldHideSuggestions,
      }

    default: {
      return state
    }
  }
}
